import { FunctionComponent, useEffect } from 'react';

import {
  GetStaticProps,
  GetStaticPropsResult,
  type GetStaticPaths
} from 'next';

import MainLayout from '@/react/components/layouts/Main';
import { getPageStaticProps } from '@/next-utils/getPageStaticProps';

import { RecommendationContext } from '@/configs/recommendations';
import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { middleware } from '@/next-utils/middleware';
import Recommendations from '@/react/components/product-list/recommendation/Recommendations';
import { GenericContent } from '@/react/components/cms-content';
import { IPage, PageType } from '@/services/models/Page';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import ContentService from '@/services/isomorphic/deprecated/ContentService';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import {
  CMSPlacementTypes,
  ContentModel,
  IContent,
  INavigationContent
} from '@/services/models/Content';

import { getDefaultStaticPaths } from '@/next-utils/getDefaultStaticPaths';

interface IHomePageProps {
  /** Page content coming from the Content Service. */
  pageContent?: IContent;
  /**
   * Navigation Content to be passed to the header and footer. This
   * includes global content.
   */
  navigationContent: INavigationContent;

  /** The page meta data used for SEO content and analytics. */
  page: IPage;
}

/**
 * The HomePage page.
 */
const HomePage: FunctionComponent<IHomePageProps> = ({
  pageContent,
  navigationContent,
  page
}) => {
  const { products, title, isRecommendationEnabled, isSlideOnMobile } =
    useRecommendedProducts(RecommendationContext.HomePage);

  useEffect(() => {
    UserInteractionService.makeAction({ action: EventType.PageView, page });
  }, []);

  const pageContentModel = pageContent ? ContentModel.from(pageContent) : null;
  const mainContent = pageContentModel?.getPlacement(CMSPlacementTypes.Main);

  const homepageConfig =
    ConfigurationService.getConfig('recommendations').getSetting(
      'homepage'
    ).value;

  // TODO: implement <meta name="keywords" content="sanuk, sanook, sunuk, snooks, snook">
  return (
    <MainLayout navigationContent={navigationContent} page={page}>
      {pageContentModel && mainContent && (
        <GenericContent placement={mainContent} />
      )}
      {isRecommendationEnabled && (
        <Recommendations
          isSlideOnMobile={isSlideOnMobile}
          products={products}
          title={title}
        />
      )}
    </MainLayout>
  );
};

export const getStaticProps: GetStaticProps = middleware<GetStaticProps>(
  async (ctx): Promise<GetStaticPropsResult<any>> => {
    const options = {
      // The homepage path.
      path: '',
      pageType: PageType.Home
    };

    return getPageStaticProps({ ctx, options });
  }
);

/**
 * Generates the list of paths that should be statically pregenerated at build
 * time for this page.
 *
 * @see https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-paths
 * @returns Static paths for the page.
 */
export const getStaticPaths: GetStaticPaths = middleware(() =>
  getDefaultStaticPaths()
);

export default HomePage;
